<template>
  <v-container style="height: 100%;">
    <v-row justify="center" align="center" style="height: 100%;">
      <v-col cols="12" sm="6" md="5" lg="4">
        <v-form v-model="valid" v-on:submit.prevent="login">
          <v-card
            :loading="loading"
            :elevation="$vuetify.breakpoint.xs ? 0 : 4"
          >
            <template slot="progress">
              <v-progress-linear
                color="primary"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-title class="pa-6 pb-0 d-none d-sm-block">Login</v-card-title>
            <v-card-text class="pa-6">
              
              <v-text-field
                v-model="form.un"
                prepend-icon="mdi-account-outline"
                :rules="[rules.required, rules.min]"
                label="Username"
              ></v-text-field>
              <v-text-field
                v-model="form.pw"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                label="Password"
                @click:append="show = !show"
              ></v-text-field>
              
            </v-card-text>
            <v-card-actions class="pa-6 pt-0">
              <v-spacer />
              <v-btn
                type="submit"
                color="primary"
                :disabled="loading"
                class="px-6"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
      color="error"
      v-model="snackbar"
    >
      Incorrect Username/password

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',

    data: () => ({
      snackbar: false,
      valid: false,
      loading: false,
      show: false,
      form: {
        un: '',
        pw: '',
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 5 || 'Min 5 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }),

    methods: {
      async login () {
        this.loading = true
        const { success, data } = await this.$post('election/login', this.form)
        if (success) {
          this.$emit('login', data)
        }
        else {
          this.snackbar = true
        }
        this.loading = false
      }
    }
  }
</script>
